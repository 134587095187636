













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})

export default class TenancyApplicationForm extends Mixins(View) {
  private formCtx: Context | null = null

  mounted() {
    const officeId = '21124059-027c-4bd9-bec4-dea921fe671b'; // Whitsundays

    const ctx: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: officeId,
        },
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: 'db101938-3684-429c-9df9-9889f5c12893', // Whitsundays
        },
      ],
    };

    const listingId = this.$route.query.listingId ?? undefined;

    if (listingId) {
      ctx.Items.push({
        Type: ContextItemType.Listing,
        Id: listingId as string,
      });
    }

    this.formCtx = ctx;
  }
}
